<template>
   <div class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>业务中台</el-breadcrumb-item>
            <el-breadcrumb-item>订单管理</el-breadcrumb-item>
            <el-breadcrumb-item>提现记录</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>

      <el-row class="content-box">
         <!-- 表头 -->

         <!-- 主体内容 -->
         <div class="table-box-flex">
            <!-- 表格 -->
            <div class="table-box">
               <!-- <div v-show="accountType !== 'BUSINESS'" v-if="!loading" :class="['box-icon',boxIcon ? 'mymove1':'mymove2']">
                  <i :class="boxIcon ? 'el-icon-d-arrow-left':'el-icon-d-arrow-right'" @click="boxIcon = !boxIcon"></i>
               </div> -->
               <el-row class="search-box">
                  <el-row class="search-row">
                     <el-row class="search-item">
                        <label v-text="$t('msg.login_time_a')">登录时间：</label>
                        <date-packer :datePacker="dateValue" @setDatePacker="getDatePacker"></date-packer>
                     </el-row>
                     <el-button class="bg-gradient" type="primary"  @click="handleQuery(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
                     <el-button type="primary"  plain @click="handleReset(false)"><span v-text="$t('msg.reset')">重置</span></el-button>
                  </el-row>
               </el-row>
               <el-table
                     v-loading="loading"
                     :data="tableData"
                     style="width: 100%"
                     :stripe="true">
                  <el-table-column label="序号" width="60">
                     <template slot-scope="scope">
                        {{scope.$index + 1}}
                     </template>
                  </el-table-column>
                  <el-table-column prop="companyDTO.companyName" label="商家名称"></el-table-column>
                  <el-table-column prop="extractMoney" label="提取金额"></el-table-column>
                  <el-table-column prop="extractStatus" label="提现状态"></el-table-column>
                  <el-table-column prop="createTime" label="创建时间"></el-table-column>
                  <el-table-column :label="$t('msg.operating')" fixed="right" min-width="110">
                     <template slot-scope="scope">
                        <el-button type="text" @click="changeStatus(scope.row.id)">
                           <el-link type="primary">更新状态</el-link>
                        </el-button>
                     </template>
                  </el-table-column>
               </el-table>
               <!-- 分页 -->
               <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
            </div>
         </div>
      </el-row>
   </div>
</template>

<script>
   import { urlObj } from '@/api/interface'
   import {order} from "@/api/interface/business";
   import { mapState } from 'vuex'
   export default {
      computed:{
         ...mapState(['hotelInfo'])
      },
      watch:{
         hotelInfo:{
            handler(newVal,oldVal){
               if(newVal.id !== oldVal.id && oldVal){
                  this.hotelId = this.hotelInfo.id
                  this.getWithdrawal()
               }
            }
         }
      },
      data(){
         return{
            tableData: [],        // 表格数据
            sideData: [],         // 侧边栏数据
            /*sideLoad: true,       // 侧边栏加载效果*/
            loading: false,        // 表格加载效果
            boxIcon: true,        // 侧边栏展开
            accountType: '',      // 账号类型
            hotelId: '',          // 酒店ID
            page: 1,              // 当前页 默认第1页
            limit: 0,             // 每页显示数
            total: 0,             // 总数目数
            dateValue: [],        // 时间段
            beginCreateTime: '',  // 开始创建时间
            endCreateTime: '',    // 结束创建时间
            change_success: '更新成功！',
         }
      },
      mounted() {
         this.limit = sessionStorage.getItem('pageSize') * 1
         let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
         this.accountType = userInfo.accountType
         // if (this.accountType === 'TENANT') {
         //    this.companyId = userInfo.tenantId
         //    this.getHotels()
         // }
         if (this.accountType === 'BUSINESS'){
            this.companyId = userInfo.storeId
            this.boxIcon = false
            this.getWithdrawal()
         }
      },
      methods: {
         // 获取酒店列表
         getHotels(){
            let url = order.getHotels
            let param = {
               companyId: this.companyId
            }
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  this.sideLoad = false
                  let sideData = res.records
                  this.sideData = sideData.map(item => {
                     return {hotelId: item.id, hotelName: item.hotelName}
                  })
                  this.$refs.sideTable.setCurrentRow(this.sideData[0]);
                  this.hotelId = this.sideData[0].hotelId
                  this.getWithdrawal()
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 获取提现记录
         getWithdrawal(){
            const url = urlObj.getWithdrawal
            const param = {
               hotelId: this.hotelId,
               limit: this.limit,
               page: this.page
            }
            if (this.accountType === 'BUSINESS'){
               delete param.hotelId
               param.companyId = this.companyId
            }
            this.$axios.get(url, param).then(res => {
               if (res.success){
                  this.loading = false
                  this.total = res.total
                  this.tableData = res.records
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 获取流水时间段
         getDatePacker(val){
            this.dateValue = val
         },
         // 搜索登录日志
         handleQuery(){
            if (this.dateValue) {
               this.beginCreateTime = this.dateValue[0]
               this.endCreateTime = this.dateValue[1]
            } else {
               this.beginCreateTime = ''
               this.endCreateTime = ''
            }
            this.getFlowBill()
         },
         // 重置搜索日志
         handleReset(){
            this.dateValue = []
         },
         // 改变显示条数
         pageChange(v){
            this.limit = v
            this.getLogs()
         },
         // 改变当前页
         handlePaging(v){
            this.page = v
            this.getLogs()
         },
         // 更新提现状态
         changeStatus(id){
            const url = urlObj.updateExtractStatus
            const param = {
               id,
               extractStatus: 'HAVE_MONEY'
            }
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  this.$message({
                     showClose: true,
                     message: this.change_success,
                     type: 'success'
                  })
                  this.getWithdrawal()
               }
            })
         }
      }
   }
</script>

<style scoped></style>
